import { Modal } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { FC, ReactElement } from "react";
import ordinaryColors from "themes/ordinaryColors";

interface Props {
  open: boolean;
  modalWidth?: string;
  handleModal?: (value: boolean) => void;
  children: ReactElement;
  sx?: SxProps;
  style?: SxProps;
  minModalWidth?: string;
}

const defaultStyle = {
  bgcolor: ordinaryColors.functionalWhite,
  borderRadius: "12px",
  boxShadow:
    "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
  left: "50%",
  padding: "24px 0px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const MinModal: FC<Props> = ({
  handleModal,
  open,
  children,
  modalWidth = "1167",
  sx,
  style = defaultStyle,
  minModalWidth,
}) => {
  return (
    <Modal sx={sx} open={open} onClose={handleModal}>
      <Box sx={{ ...style, width: modalWidth, minWidth: minModalWidth }}>{children}</Box>
    </Modal>
  );
};

export default MinModal;
