import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  migrationNote:
    "Due to the data migration from the previous database version, some information may be missing or incorrect in this certificate. This information must be updated by the responsible authority.",
  migrationNoteTooltipCertificate:
    "In this certificate some information may be missing or incorrect. It must be updated by the responsible authority.",
  migrationNoteTooltipUnit:
    "In this certificate some information may be missing or incorrect. It must be updated by the responsible authority.",
  noDataAvailable: "No data available...",
  noUploadedFiles: "No documents have been uploaded for this certifcate",
  reducedFunctionalityNote: `Please note:
  ${nextLine()}The second phase of uploading unit and component certificates is now unlocked. This update now includes all statuses, especially the status 'Withdrawn replaced by'. In addition, it is now possible to enter prototype and manufacturer declarations.
  ${nextLine()}In the next step, it will be possible to upload certificates with performance areas and supplement the imported certificates.`,
};
