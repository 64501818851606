export default {
  columnOptions: "Spaltenoptionen",
  searchTableDefault: "Tabelle durchsuchen",
  editUser: "Benutzer bearbeiten",
  blockUser: "Benutzer blockieren",
  editTenant: "Organisation bearbeiten",
  sort: {
    labelAsc: "Aufwärts",
    labelDesc: "Abwärts",
  },
  pagination: { rowsPerPage: "Einträge pro Seite:" },
};
