import {
  BullitList,
  BullitTitle,
  LegalNoteLink,
  nextLine,
  nextParagraph,
} from "components/mlpaUiComponents/LegalNote/lib";

const rightsContentBullitItems = {
  rightToInformation: [
    "the processing purposes",
    "the categories of personal data that are processed",
    "the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations",
    "if possible, the planned period for which the personal data will be stored or, if this is not possible, the criteria for determining that period",
    "the existence of a right to rectification or deletion of personal data concerning you or to restriction of processing by the controller or a right to object to this processing",
    "the existence of a right to lodge a complaint with a supervisory authority",
    "if the personal data is not collected from the data subject: all available information about the origin of the data",
    "the existence of automated decision-making including profiling in accordance with Article 22 Paragraphs 1 and 4 GDPR and - at least in these cases - meaningful information about the logic involved as well as the scope and intended effects of such processing for the data subject",
  ],
  rightToDeletion: [
    "The personal data was collected or otherwise processed for purposes for which it is no longer necessary.",
    "The data subject revokes their consent on which the processing was based in accordance with Article 6 Paragraph 1 Letter a GDPR or Article 9 Paragraph 2 Letter a GDPR and there is no other legal basis for the processing.",
    "The data subject objects to the processing in accordance with Article 21 (1) of the GDPR and there are no overriding legitimate reasons for the processing, or the data subject objects to the processing in accordance with Article 21 (2) of the GDPR processing.",
    "The personal data was processed unlawfully.",
    "The deletion of personal data is necessary to comply with a legal obligation under Union or Member State law to which the controller is subject.",
    "The personal data was collected in relation to information society services offered in accordance with Article 8 Para. 1 GDPR.",
  ],
  rightToRestrictionOfProcessing: [
    "The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.",
    "The processing is unlawful, the data subject refuses the deletion of the personal data and instead requests the restriction of the use of the personal data.",
    "The controller no longer needs the personal data for the purposes of processing, but the data subject needs them to assert, exercise or defend legal claims.",
    "The data subject has lodged an objection to the processing in accordance with Article 21 Paragraph 1 of the GDPR and it is not yet clear whether the legitimate reasons of the controller outweigh those of the data subject.",
  ],
};

export default {
  automatedDecisionMaking: "Existence of automated decision making",
  automatedDecisionMakingContent: `
    As a responsible company, we do not use automatic decision-making or profiling.
    ${nextParagraph()}This data protection declaration was created by the data protection declaration generator of DGD Deutsche Gesellschaft für Datenschutz GmbH, which acts as the 
    ${LegalNoteLink({
      url: "https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/",
      label: "external data protection officer for Cologne",
    })}, in cooperation with the 
    ${LegalNoteLink({
      url: "https://www.wbs-law.de/",
      label: "Cologne lawyer for data protection law Christian Solmecke",
    })}.
  `,
  cookies: "Cookies",
  cookiesContent: `
    The FGW website uses cookies. Cookies are text files that are stored and stored on a computer system via an Internet browser.
    ${nextParagraph()}Numerous websites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This enables the visited websites and servers to distinguish the individual browser of the data subject from other internet browsers that contain other cookies. A specific Internet browser can be recognized and identified via the unique cookie ID.
    ${nextParagraph()}By using cookies, FGW can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.
    ${nextParagraph()}Using a cookie, the information and offers on our website can be optimized for the user. As already mentioned, cookies enable us to recognize the users of our website. The purpose of this recognition is to make it easier for users to use our website. For example, the user of a website that uses cookies does not have to re-enter their access data each time they visit the website because this is done by the website and the cookie stored on the user's computer system. Another example is the cookie of a shopping cart in the online shop. The online shop remembers the items that a customer has placed in the virtual shopping cart via a cookie.
    ${nextParagraph()}The data subject can prevent the setting of cookies through our website at any time by means of an appropriate setting in the Internet browser used and thus permanently object to the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via an internet browser or other software programs. This is possible in all common internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be fully usable.
  `,
  collectionOfData: "Collection of general data and information",
  collectionOfDataContent: `
    The FGW  website collects a series of general data and information each time the website is accessed by a data subject or an automated system. This general data and information is stored in the server’s log files. What can be recorded are the (1) browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrers), (4) the sub-websites which are accessible via an accessing system on our website is controlled, (5) the date and time of access to the website, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) other similar data and information that serves to protect against threats in the event of attacks on our information technology systems.
    ${nextParagraph()}When using this general data and information, FGW does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website and the advertising for it, (3) ensure the long-term functionality of our information technology systems and the technology of our website and ( 4) to provide law enforcement authorities with the information necessary for criminal prosecution in the event of a cyber attack. These anonymously collected data and information are therefore evaluated by FGW both statistically and with the aim of increasing data protection and data security in our company in order to ultimately ensure an optimal level of protection for the personal data we process. The anonymous data in the server log files is stored separately from all personal data provided by a data subject.
  `,
  dataProtection: "Data protection for applications and the application process",
  dataProtectionContent: `
    The person responsible for processing collects and processes the personal data of applicants for the purpose of processing the application process. Processing can also take place electronically. This is particularly the case if an applicant submits relevant application documents to the person responsible for processing electronically, for example by email or via a web form on the website. If the person responsible for processing concludes an employment contract with an applicant, the data transmitted will be stored for the purpose of processing the employment relationship in compliance with legal regulations. If the person responsible for processing does not conclude an employment contract with the applicant, the application documents will be automatically deleted two months after the rejection decision is announced, provided that deletion does not conflict with any other legitimate interests of the person responsible for processing. Other legitimate interests in this sense include, for example, a burden of proof in proceedings under the General Equal Treatment Act (AGG).
  `,
  definitions: "Definitions",
  definitionsContent: `
    The data protection declaration of the FGW is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.
    ${nextParagraph()}In this data protection declaration, we use, inter alia, the following terms:
    ${BullitTitle({ title: "a) Personal data" })}
    Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
    ${BullitTitle({ title: "b) Data subject" })}
    Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.
    ${BullitTitle({ title: "c) Processing" })}
    Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
    ${BullitTitle({ title: "d) Restriction of processing" })}
    Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future.
    ${BullitTitle({ title: "e) Profiling" })}
    Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.
    ${BullitTitle({ title: "f) Pseudonymisation" })}
    Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.
    ${BullitTitle({ title: "g) Controller or controller responsible for the processing" })} 
    Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law.
    ${BullitTitle({ title: "h) Processor" })}
    Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
    ${BullitTitle({ title: "i) Recipient" })}
    Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the 2. Name and Address of the controller processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing.
    ${BullitTitle({ title: "j) Third Party" })}
    Third party means a natural or legal person, public authority, agency or body other than the data subject, the controller, the processor and the persons who, under the direct authority of the controller or the processor, are authorised to process the personal data.
    ${BullitTitle({ title: "k) Consent" })}
    Consent means any freely given, informed and unambiguous indication of the data subject's wishes, in the form of a statement or other unambiguous affirmative action, by which the data subject indicates that he or she consents to the processing of personal data concerning him/her.
  `,
  fgwAdvisoryBoard: "FGW Advisory Board",
  fgwAdvisoryBoardContent: `
    The FGW Advisory Board is a body appointed by the FGW Board of Directors which, in accordance with the requirements of the TR 8 certification regulation and the Technical Committee for Electrical Properties, as well as at the instigation of a requirement from the VDE-FNN grid connection rules, carries out a process for the approval of technical experts for the preparation of equipment, component and plant certifiers.
    ${nextParagraph()}In order to participate in the admission process, the submitted application documents must be exchanged and discussed with the advisory board. Anyone who applies to FGW for approval as a provisionally or definitively approved responsible person within the framework of the process description agrees to the disclosure of the documents submitted to FGW to the FGW Advisory Board. The current composition of the Advisory Board can be communicated at any time via the Secretariat.
  `,
  general: "General",
  generalContent: `
    We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the FGW. The use of the Internet pages of the FGW is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary.If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.
    ${nextParagraph()}By means of this data protection declaration, FGW would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.
    ${nextParagraph()}As the controller, the FGW has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.
  `,
  googleAnalytics:
    "Data protection regulations on the application and use of Google Analytics (with anonymization function)",
  googleAnalyticsContent: `
    The person responsible for processing has integrated the Google Analytics component (with anonymization function) on this website. Google Analytics is a web analysis service. Web analysis is the collection, collection and evaluation of data about the behavior of visitors to websites. A web analysis service collects, among other things, data about the website from which a data subject came to a website (so-called referrer), which sub-pages of the website were accessed or how often and for what duration a sub-page was viewed. Web analysis is primarily used to optimize a website and to analyze the cost-benefit of internet advertising.
    ${nextParagraph()}The operating company of the Google Analytics component is Google Inc., 1600 Amphitheater Pkwy, Mountain View, CA 94043-1351, USA.
    ${nextParagraph()}The person responsible for processing uses the addition “_gat._anonymizeIp” for web analysis via Google Analytics. Using this addition, the IP address of the data subject's Internet connection is shortened and anonymized by Google if our website is accessed from a member state of the European Union or from another state party to the Agreement on the European Economic Area.
    ${nextParagraph()}The purpose of the Google Analytics component is to analyze the flow of visitors to our website. Google uses the data and information obtained, among other things, to evaluate the use of our website, to compile online reports for us that show the activities on our website, and to provide other services related to the use of our website.
    ${nextParagraph()}Google Analytics sets a cookie on the information technology system of the data subject. What cookies are has already been explained above. By setting the cookie, Google is able to analyze the use of our website. Each time you access one of the individual pages of this website, which is operated by the controller and into which a Google Analytics component has been integrated, the Internet browser on the information technology system of the data subject is automatically triggered by the respective Google Analytics component to transmit data to Google for online analysis purposes. As part of this technical process, Google gains knowledge of personal data, such as the IP address of the data subject, which Google uses, among other things, to track the origin of visitors and clicks and subsequently enable commission billing.
    ${nextParagraph()}The cookie is used to store personal information, such as the access time, the location from which access was made and the frequency of visits to our website by the data subject. Each time you visit our website, this personal data, including the IP address of the Internet connection used by the data subject, is transmitted to Google in the United States of America. This personal data is stored by Google in the United States of America. Google may pass on the personal data collected via the technical process to third parties.
    ${nextParagraph()}The person concerned can prevent the setting of cookies through our website, as already described above, at any time by means of a corresponding setting in the Internet browser used and thus permanently object to the setting of cookies. Such a setting of the Internet browser used would also prevent Google from setting a cookie on the information technology system of the data subject. In addition, a cookie already set by Google Analytics can be deleted at any time via the Internet browser or other software programs.
    ${nextParagraph()}The data subject also has the possibility of objecting to a collection of data generated by Google Analytics relating to the use of this website as well as the processing of this data by Google and the chance to prevent such. To do this, the data subject must download and install a browser add-on under the link 
    ${LegalNoteLink({
      url: "https://tools.google.com/dlpage/gaoptout",
      label: "https://tools.google.com/dlpage/gaoptout",
    })}. This browser add-on tells Google Analytics via JavaScript that no data or information about website visits may be transmitted to Google Analytics. Installing the browser add-on is viewed by Google as a contradiction. If the information technology system of the data subject is deleted, formatted or reinstalled at a later date, the data subject must reinstall the browser add-on in order to deactivate Google Analytics. If the browser add-on is uninstalled or deactivated by the data subject or another person within their sphere of control, it is possible to reinstall or reactivate the browser add-on.
    ${nextParagraph()}Further information and Google's applicable data protection regulations can be found at 
    ${LegalNoteLink({
      url: "https://www.google.de/intl/de/policies/privacy/",
      label: "https://www.google.de/intl/de/policies/privacy/",
    })} and at 
    ${LegalNoteLink({
      url: "http://www.google.com/analytics/terms/de.html",
      label: "http://www.google.com/analytics/terms/de.html",
    })}. Google Analytics is explained in more detail at this link 
    ${LegalNoteLink({
      url: "https://www.google.com/intl/de_de/analytics/",
      label: "https://www.google.com/intl/de_de/analytics/",
    })}.
  `,
  legalBasisForProcessing: "Legal basis for processing",
  legalBasisForProcessingContent: `
    Art. 6 I lit. a GDPR serves our company as the legal basis for processing operations in which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for the delivery of goods or the provision of any other service or consideration, the processing is based on Art. 6 I lit. b GDPR. The same applies to processing operations that are necessary to carry out pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation that requires the processing of personal data, such as to fulfill tax obligations, the processing is based on Art. 6 I lit. c GDPR. In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or another natural person. This would be the case, for example, if a visitor were injured in our company and his name, age, health insurance details or other vital information would then have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d GDPR. Ultimately, processing operations could be based on Art. 6 I lit. f GDPR. Processing operations that are not covered by any of the above-mentioned legal bases are based on this legal basis if the processing is necessary to protect a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject do not prevail. We are permitted to carry out such processing operations in particular because they have been specifically mentioned by the European legislator. In this respect, he was of the opinion that a legitimate interest could be assumed if the data subject is a customer of the controller (Recital 47 Sentence 2 GDPR).
  `,
  legalRequirements:
    "Legal or contractual requirements for the provision of personal data; Necessity for the conclusion of the contract; Obligation of the data subject to provide the personal data; possible consequences of non-provision",
  legalRequirementsContent: `
    We would like to clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner). In order to conclude a contract, it may sometimes be necessary for a data subject to provide us with personal data, which must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data if our company enters into a contract with them. Failure to provide the personal data would mean that the contract with the person concerned could not be concluded. Before the data subject provides personal data, the data subject must contact one of our employees. Our employee will inform the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and what consequences the non-provision of the personal data would have.
  `,
  legitimateInterests: "Legitimate interests in the processing pursued by the controller or a third party",
  legitimateInterestsContent: `
    If the processing of personal data is based on Article 6 I lit. f GDPR, our legitimate interest is to carry out our business activities for the benefit of the well-being of all our employees and our shareholders.
  `,
  nameAndAddressOfController: "Name and adress of the controller",
  nameAndAddressOfControllerContent: `
    The person responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the member states of the European Union and other provisions of a data protection nature is:
    ${nextParagraph()}FGW e.V.
    ${nextParagraph()}Oranienburger Str. 45
    ${nextParagraph()}10117 Berlin
    ${nextParagraph()}Germany
    ${nextParagraph()}Phone: +49 (0) 30/30 10 15 05-0
    ${nextParagraph()}Email: datenschutz@wind-fgw.de
    ${nextParagraph()}Website: 
    ${LegalNoteLink({
      url: "https://www.wind-fgw.de",
      label: "www.wind-fgw.de",
    })}
  `,
  nameAndAddressOfProtectionOfficer: "Name and address of the data protection officer",
  nameAndAddressOfProtectionOfficerContent: `
    If you have any concerns regarding data protection, please contact:
    ${nextParagraph()}FGW e.V.
    ${nextParagraph()}Oranienburger Str. 45
    ${nextParagraph()}10117 Berlin
    ${nextParagraph()}Germany
    ${nextParagraph()}Phone: +49 (0) 30/30 10 15 05-0
    ${nextParagraph()}Email: datenschutz@wind-fgw.de
    ${nextParagraph()}Website: 
    ${LegalNoteLink({
      url: "https://www.wind-fgw.de",
      label: "www.wind-fgw.de",
    })}
    ${nextParagraph()}Any data subject can contact our data protection officer directly at any time with any questions or suggestions regarding data protection.
  `,
  newsletterTracking: "Newsletter-Tracking",
  newsletterTrackingContent: `
    FGW's newsletters contain so-called tracking pixels. A web beacon is a miniature graphic that is embedded in such e-mails, which are sent in HTML format to enable log file recording and log file analysis. As a result, a statistical evaluation of the success or failure of online marketing campaigns can be carried out. Based on the embedded tracking pixel, FGW can identify whether and when an e-mail has been opened by a data subject and which links in the e-mail have been accessed by the data subject.
    ${nextParagraph()}Such personal data collected via the tracking pixels contained in the newsletters are stored and evaluated by the controller in order to optimize the sending of the newsletter and to adapt the content of future newsletters even better to the interests of the data subject. This personal data will not be passed on to third parties. Data subjects are entitled at any time to revoke the separate declaration of consent given in this regard via the double opt-in procedure. After revocation, this personal data will be deleted by the controller. If you unsubscribe from receiving the newsletter, FGW will automatically interpret it as a revocation.
  `,
  registrationOnWebsite: "Registration on our website",
  registrationOnWebsiteContent: `
    The data subject has the opportunity to register on the controller's website by providing personal data. Which personal data is transmitted to the controller in the process is determined by the respective input mask used for registration. The personal data entered by the data subject will be collected and stored exclusively for internal use by the controller and for its own purposes. The controller may arrange for the transfer to one or more processors, such as a parcel service provider, who will also use the personal data exclusively for internal use attributable to the controller.
    ${nextParagraph()}Registration on the controller's website also stores the IP address assigned by the data subject's Internet service provider (ISP), the date and time of registration. The storage of this data takes place against the background that this is the only way to prevent the misuse of our services and, if necessary, to make it possible to solve crimes that have been committed. In this respect, the storage of this data is necessary to secure the controller. As a matter of principle, this data will not be passed on to third parties, unless there is a legal obligation to pass it on or the disclosure serves the purpose of law enforcement.
    ${nextParagraph()}The registration of the data subject with the voluntary provision of personal data serves the purpose of the Data Controller to offer the data subject content or services that, due to the nature of the matter, can only be offered to registered users. Registered persons are free to amend the personal data provided during registration at any time or to have them completely deleted from the controller's database.
    ${nextParagraph()}The controller shall, at any time, upon request, provide each data subject with information about which personal data about the data subject is stored. Furthermore, the controller corrects or deletes personal data at the request or notice of the data subject, insofar as this does not conflict with statutory retention obligations. All of the data controller's employees are available to the data subject as contact persons in this context.
  `,
  rights: "Rights of the data subject",
  rightsContent: `
    ${BullitTitle({ title: "a) Right to confirmation", nextParagraph: false })}
    ${nextParagraph()}Every data subject has the right granted by the European legislator to obtain confirmation from the controller as to whether personal data concerning him or her is being processed. If a data subject would like to exercise this right of confirmation, they can contact an employee of the person responsible for processing at any time.
    ${BullitTitle({ title: "b) Right to information" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to obtain free information from the person responsible for processing at any time about the personal data stored about him and a copy of this information. Furthermore, the European legislator has granted the data subject access to the following information:
    ${BullitList({ content: rightsContentBullitItems.rightToInformation })}
    The data subject also has the right to information as to whether personal data has been transmitted to a third country or to an international organization. If this is the case, the data subject also has the right to receive information about the appropriate guarantees in connection with the transfer.
    ${nextLine()}If a data subject would like to exercise this right to information, they can contact an employee of the person responsible for processing at any time.
    ${BullitTitle({ title: "c) Right to rectification" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to request the immediate correction of incorrect personal data concerning them. Furthermore, the data subject has the right to request the completion of incomplete personal data, including by means of a supplementary statement, taking into account the purposes of the processing.
    ${nextLine()}If a data subject would like to exercise this right to rectification, they can contact an employee of the data controller at any time.
    ${BullitTitle({ title: "d) Right to deletion (right to be forgotten)" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to request that the person responsible delete the personal data concerning him or her immediately if one of the following reasons applies and if the processing is not necessary:
    ${BullitList({ content: rightsContentBullitItems.rightToDeletion })}
    If one of the reasons mentioned above applies and a data subject wishes to have personal data stored at FGW deleted, they can contact an employee of the data controller at any time. The FGW employee will ensure that the deletion request is complied with immediately.
    ${nextParagraph()}If the personal data has been made public by FGW and our company, as the controller, is obliged to delete the personal data in accordance with Article 17 Paragraph 1 of the GDPR, FGW will take appropriate measures, including technical measures, taking into account the available technology and the implementation costs to inform other data controllers who process the published personal data that the data subject has requested that these other data controllers delete all links to that personal data or copies or replications of that personal data , unless processing is necessary. The FGW employee will take the necessary measures in individual cases.
    ${nextParagraph()}If the reason for collecting personal data is related to a FGW service, if the data is deleted, the FGW service can be canceled without further action.
    ${BullitTitle({ title: "e) Right to restriction of processing" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to request that the controller restrict the processing if one of the following conditions is met:
    ${BullitList({ content: rightsContentBullitItems.rightToRestrictionOfProcessing })}
    If one of the above conditions is met and a data subject wishes to request the restriction of personal data stored by FGW, they can contact an employee of the data controller at any time. The FGW employee will arrange for the processing to be restricted.
    ${BullitTitle({ title: "f) Right to data portability" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to receive the personal data concerning him or her, which the data subject has provided to a person responsible, in a structured, common and machine-readable format. You also have the right to transmit this data to another controller without hindrance from the controller to whom the personal data was provided, provided that the processing is based on consent in accordance with Article 6 Paragraph 1 Letter a of the GDPR or Article 9 Paragraph 2 letter a GDPR or on a contract in accordance with Article 6 paragraph 1 letter b GDPR and the processing is carried out using automated procedures, unless the processing is necessary for the performance of a task that is in the public interest or is carried out in the exercise of official authority, which has been transferred to the person responsible.
    ${nextParagraph()}Furthermore, when exercising his or her right to data portability in accordance with Article 20 (1) of the GDPR, the data subject has the right to have the personal data transmitted directly from one person responsible to another person responsible, to the extent that this is technically feasible and provided that This does not affect the rights and freedoms of other people.
    ${nextParagraph()}To assert the right to data portability, the data subject can contact a FGW employee at any time.
    ${BullitTitle({ title: "g) Right to object" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to object at any time, for reasons arising from his or her particular situation, to the processing of personal data concerning him or her based on Article 6 Paragraph 1 Letter e or f GDPR, to lodge an objection. This also applies to profiling based on these provisions.
    In the event of an objection, FGW will no longer process the personal data unless we can demonstrate compelling legitimate reasons for the processing which outweigh the interests, rights and freedoms of the data subject, or the processing serves to assert, exercise or defend legal claims.
    If FGW processes personal data in order to conduct direct advertising, the data subject has the right to object at any time to the processing of personal data for the purpose of such advertising. This also applies to profiling insofar as it is connected to such direct advertising. If the data subject objects to FGW processing for direct advertising purposes, FGW will no longer process the personal data for these purposes.
    In addition, the data subject has the right, for reasons arising from his or her particular situation, to object to the processing of personal data concerning him or her by the FGW for scientific or historical research purposes or for statistical purposes in accordance with Article 89 (1) of the GDPR to lodge an objection, unless such processing is necessary to fulfill a task carried out in the public interest.
    To exercise the right to object, the data subject may contact any FGW employee or another employee directly. Furthermore, in connection with the use of information society services, the data subject is free, notwithstanding Directive 2002/58/EC, to exercise his or her right to object by means of automated procedures using technical specifications.
    ${BullitTitle({ title: "h) Automated decisions in individual cases including profiling" })}
    Every person affected by the processing of personal data has the right granted by the European legislator not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him, provided that the decision (1) is not necessary for entering into or performance of a contract between the data subject and the controller, or (2) is authorized by Union or Member State law to which the controller is subject and that legislation appropriates measures to safeguard the rights and freedoms as well as the legitimate interests of the data subject or (3) is carried out with the express consent of the data subject.
    If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) it is based on the data subject's explicit consent, the FGW shall implement appropriate measures to safeguard the rights and freedoms and legitimate interests of the person concerned, which includes at least the right to obtain human intervention on the part of the person responsible, to express one's own point of view and to challenge the decision.
    If the data subject would like to assert rights with regard to automated decisions, he or she can contact an employee of the data controller at any time.
    ${BullitTitle({ title: "i) Right to revoke consent under data protection law" })}
    Every person affected by the processing of personal data has the right granted by the European legislator to revoke consent to the processing of personal data at any time.
    If the data subject would like to exercise their right to withdraw consent, they can contact an employee of the data controller at any time.
  `,
  routineDeletionAndBlocking: "Routine deletion and blocking of personal data",
  routineDeletionAndBlockingContent: `
    The person responsible for processing processes and stores personal data of the data subject only for the period necessary to achieve the purpose of storage or if this is required by the European legislator or another legislator in laws or regulations which the person responsible for processing is subject to.
    ${nextParagraph()}If the storage purpose no longer applies or if a storage period prescribed by the European legislator or another responsible legislator expires, the personal data will be blocked or deleted routinely and in accordance with legal regulations.
  `,
  storageDuration: "Duration for which the personal data is stored",
  storageDurationContent: `
    The criterion for the duration of storage of personal data is the respective statutory retention period. After the deadline has expired, the relevant data will be routinely deleted unless it is no longer required to fulfill the contract or initiate a contract.
  `,
  subscribeToNewsletter: "Subscribe to our newsletter",
  subscribeToNewsletterContent: `
    On the FGW website, users are given the opportunity to subscribe to our company's newsletter. Which personal data is transmitted to the data controller when the newsletter is ordered is determined by the input mask used for this purpose.
    ${nextParagraph()}FGW informs its customers and business partners about the company's offers at regular intervals by means of a newsletter. In principle, the newsletter of our company can only be received by the data subject if (1) the data subject has a valid e-mail address and (2) the data subject registers to receive the newsletter. For legal reasons, a confirmation e-mail will be sent to the e-mail address entered by a data subject for the first time for the newsletter dispatch using the double opt-in procedure. This confirmation e-mail is used to check whether the owner of the e-mail address as the data subject has authorized the receipt of the newsletter.
    ${nextParagraph()}When subscribing to the newsletter, we also store the IP address assigned by the Internet service provider (ISP) of the computer system used by the data subject at the time of registration, as well as the date and time of registration. The collection of this data is necessary in order to be able to trace the (possible) misuse of the e-mail address of a data subject at a later point in time and therefore serves to provide legal protection for the controller.
    ${nextParagraph()}The personal data collected as part of a subscription to the newsletter will be used exclusively for sending our newsletter. Furthermore, subscribers to the newsletter may be informed by e-mail if this is necessary for the operation of the newsletter service or a registration in this regard, as could be the case in the event of changes to the newsletter offer or changes in technical conditions. The personal data collected as part of the newsletter service will not be passed on to third parties. The subscription to our newsletter can be cancelled by the data subject at any time. The consent to the storage of personal data that the data subject has given us for the purpose of sending the newsletter can be revoked at any time. For the purpose of revoking consent, a corresponding link can be found in each newsletter. Furthermore, it is possible to unsubscribe from the newsletter at any time directly on the website of the controller or to inform the controller of this in another way.
  `,
};
