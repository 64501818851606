export default {
  animation: {
    description:
      "Ihre Filter haben keine Ergebnisse ergeben. Bitte versuchen Sie es erneut, indem Sie Ihre Filter anpassen oder löschen.",
    title: "Keine passenden Suchergebnisse",
  },
  certificateCheck: "Zertifikatsprüfung",
  certificateHistory: "Zertifikatsverlauf",
  certificateInfo: "Zertifikatsinformationen",
  certificateStatus: "Zertifikatstatus",
  certificateStatuses: {
    expired: "Abgelaufen",
    expiring: "Läuft ab",
    replaced: "Ersetzt",
    valid: "Gültig",
    withdrawn: "Zurückgezogen",
  },
  description:
    "Die FGW e.V. vernetzt die Energiebranche und vereinheitlicht außerdem die Fachsprache der dezentralen und erneuerbaren Energien. In diesem Abschnitt finden Sie die Einheiten- und Komponentenzertifikate für die verschiedene Spannungsebenen. Hier können Sie überprüfen, ob diese Zertifikate von einer akkreditierten Zertifizierungsstelle geprüft wurden und ihren aktuellen Gültigkeitsstatus einsehen.",
  editParameters: "Parameter bearbeiten",
  editCertificate: "Zertifikat bearbeiten",
  errorNote: ({ entity, modelName }: { entity: "unit" | "certificate"; modelName: string | undefined }) => {
    let errorNote = `Bitte beschreiben Sie das Problem, das Sie bei dem Zertifikat ${modelName} festgestellt haben. Sobald das Problem von der verantwortlichen Partei behoben wurde, werden Sie per E-Mail benachrichtigt.`;
    if (entity === "unit")
      errorNote = `Bitte beschreiben Sie das Problem, das Sie bei dem Gerät ${modelName} festgestellt haben. Sobald das Problem von der verantwortlichen Partei behoben wurde, werden Sie per E-Mail benachrichtigt.`;
    return errorNote;
  },
  unitEditWarning: {
    header: "Bitte verlassen Sie den Bearbeitungsmodus, um fortzufahren.",
    info: "Sie bearbeiten derzeit die Parameter einer Einheit. Mögliche Änderungen gehen möglicherweise verloren. Bitte verlassen Sie den Bearbeitungsmodus, um fortzufahren.",
  },
  filterTitles: {
    certificateStatus: "Zertifikatstatus",
    certificateType: "Nachweisdokument",
    authority: "Zertifizierungsstelle",
    manufacturer: "Hersteller",
    maxActivePower: "Maximale Wirkleistung",
    norm: "Norm",
    ownCertificates: "Eigene Zertifikate",
    primaryEnergy: "Primärenergie",
    ratedPower: "Wirkleistung",
    ratedVoltage: "Bemessungsspannung",
    subscribed: "Abonniert",
    verified: "Verifiziert",
    isNotReplaced: "Nur aktuelle Revisionen",
    zerezId: "ZEREZ ID",
  },
  label: "Einheiten und Zertifikate",
  locked: "Gesperrt",
  mostRecentCertificate: "Neuestes Zertifikat",
  notVerified: "Nicht Verifiziert",
  verify: "Verifizieren",
  reportCertificateError: "Zertifikatsfehler melden",
  reportParametersError: "Parameterfehler melden",
  showUnit: "Zur Einheit",
  since: "seit",
  subscribed: "Abonniert",
  subscribeStar: "Abonnement",
  subscribe: "Abonnieren",
  title: "ZEREZ - Zentrales Register für Einheiten- und Komponentenzertifikate",
  unitParameters: {
    //   electricalParameters: "Elektrische Parameter",
    //   fastVoltageChanges: "Schnelle Spannungsänderungen",
    //   flicker: "Flicker",
    //   generalInfo: "Allgemeine Informationen",
    label: "Geräteparameter",
    //   powerRanking: "Leistungsbewertung",
  },
  uploadedDocuments: "Hochgeladene Dokumente",
  verified: "Verifiziert",
  inCorrection: "In Korrektur",
};
