import { nextParagraph, LegalNoteLink, BullitList, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  faqSubTitle:
    "FAQs - Answers to frequently asked questions. Welcome to our FAQ section. Here you will find answers to frequently asked questions about the ZEREZ register (Central Register for Unit and Component Certificates). This FAQ section will be continuously updated. Please contact us if you have any questions, suggestions or requests.",
  qAndA: {
    whatIsZerez: {
      q: "What is Zerez?",
      a: "ZEREZ stands for the Central Register for Unit and Component Certificates. It is a comprehensive information platform for the collection and provision of certificates in the context of the grid connection of energy generation plants.",
    },
    status: {
      q: "What is the current status of ZEREZ?",
      a: `
      The German government's "
      ${LegalNoteLink({
        url: "https://www.bundestag.de/dokumente/textarchiv/2023/kw46-pa-klimaschutz-erneuerbare-energiengesetz-976490",
        label: "Solarpaket I",
      })}" also contains regulations on the introduction, maintenance, operation and further development of a central register, accessible via the Internet, for recording and monitoring energy systems and energy system components. These are specified in more detail by the provisions of the
      ${LegalNoteLink({
        url: "https://www.bundesrat.de/SharedDocs/drucksachen/2023/0401-0500/456-23.pdf?__blob=publicationFile&v=2",
        label: "Verordnung zur Änderung der Elektrotechnische-Eigenschaften-Nachweis-Verordnung",
      })} (NELEV Amendment Ordinance). Solar Package I and the NELEV Amendment Ordinance have already been approved by the Cabinet but are not yet in force.
      ${nextParagraph()}It is expected that these changes will come into force in the second quarter of 2024. The central register for unit and component certificates (ZEREZ) from FGW e. V. fulfills all the requirements of such a register. We are convinced that, regardless of this, the use of the register already brings enormous advantages for all users and offers considerable acceleration potential for grid connection.
    `,
    },
    register: {
      q: "Who can register?",
      a: "Registration is possible for manufacturers of power generation units, grid operators, certification bodies and planners, installers and operators of power generation units.",
    },
    account: {
      q: "How do I create a user account?",
      a: "You can create a user account via the Sign up tab on the ZEREZ website. Follow the steps indicated to create your account.",
    },
    login: {
      q: "Do I have to register as a planner/installer/operator?",
      a: "Registration is not required to view the register. Advanced functions are only available after successful registration.",
    },
    upload: {
      q: "How do I upload a certificate?",
      a: "Once you have successfully logged in, you can use the Upload certificate function to transfer the certificate in digital form to the ZEREZ system.",
    },
    whoCanUpload: {
      q: "Who can upload certificates?",
      a: "After successful registration, manufacturers and certification bodies can upload their certificates to the register using the 'Upload certificate' function.",
    },
    forgotPassword: {
      q: "I have forgotten my password. How can I reset it?",
      a: "Under the login page you will find the option forgot password. Follow the steps indicated to reset your password.",
    },
    fees: {
      q: "Are there any fees for registering in ZEREZ?",
      a: "ZEREZ is currently free of charge due to the requirements of the new NELEV Amendment Ordinance. There are no fees for registering and using the basic functions of the register.",
    },
    browser: {
      q: "Which browser is recommended for ZEREZ?",
      a: "ZEREZ is optimized for Google Chrome.",
    },
    units: {
      q: "Are power generation plants listed in ZEREZ?",
      a: "Unit and component certificates of decentralized power generation units are listed in ZEREZ. Power generation plants are recorded in the market master data register.",
    },
    users: {
      q: "Can a company register several users?",
      a: "Registration takes place in the register. The first person to register is also the main admin and can add further team members.",
    },
    zerezIdPurpose: {
      q: "What is the purpose of the ZEREZ register number (ZEREZ ID)?",
      a: "ZEREZ links all unit certificates to a unique register number. The ZEREZ register number (ZEREZ ID) is used to make the grid connection process more efficient. In future, it will be sufficient to enter this register number in the grid connection process and the grid operator will be able to retrieve the necessary data.",
    },
    zerezIdStructure: {
      q: "How is the ZEREZ register number (ZEREZ ID) structured?",
      a: `The ZEREZ register number, for example "ZE-R018-RV36-0001", is made up of various parts:
        ${BullitList({
          content: [
            "Prefix ZE, stands for the ZEREZ register",
            "R018: Identifier of the certification body",
            "RV36: Identifier of the certificate",
            "0001: Sequential numbering of the unit/component",
          ],
        })}
      `,
    },
    visibility: {
      q: "Who can see which data?",
      a: `
      VDE-AR-N 4105:
      ${nextLine()} The cover sheet of the verification document (VDE-AR-N 4105 - Appendix E.4/E.5) and the certificate for integrated grid and system protection or the test report for grid and system protection (VDE-AR-N 4105 - Appendix E.6/E.7) are publicly accessible. This also applies to the list of grid connection values.
      ${nextParagraph()} VDE-AR-N 4110/20/30:
      ${nextLine()} The cover sheet of the verification document [1.16] and certain data are publicly accessible. Registered users with the role of grid operator also have access to the list of grid connection values.
      `,
    },
  },
};
