export default {
  add: "Add",
  addNorm: "+Add Norm",
  addSimulationSoftware: "+Add Simulation Software",
  apply: "Apply",
  approve: "Approve",
  // assign: "Assign",
  // attributeSets: "Attribute Sets",
  back: "Back",
  cancel: "Cancel",
  // change: "Change",
  collapse: "Collapse all",
  confirm: "Confirm",
  // continue: "Continue",
  // create: "Create",
  // createdBy: "created by",
  // databaseAccess: "Units & Certificates",
  // databaseText: "Access the Public Register instantly to view publicly available module certificates",
  delete: "Delete",
  deny: "Deny",
  // done: "Done",
  // download: "Download",
  // edit: "Edit",
  errorReportButtonLabel: "Report Error",
  expand: "Expand all",
  // filters: "Filters",
  // hide: "hide related assets",
  homePage: "Home Page",
  logIn: "Log In",
  logOut: "Log Out",
  // loginText: "To access all features, simply log in with your username and password",
  new: "New",
  // newLog: " New Log",
  // next: "Next",
  no: "No",
  continue: "Continue",
  // OK: "OK",
  refreshTable: "Refresh Table",
  // registration: "Registration",
  // registrationText: "Don`t have an account yet? Click here to create one.",
  reload: "Reload",
  // replace: "replace",
  // reserve: "Reserve",
  reset: "Reset",
  resetAllFilters: "Reset all filters",
  // run: "Run",
  save: "Save",
  saveChanges: "Save Changes",
  // send: "Send",
  sendInvite: "Send Invite",
  // settings: "Settings",
  // show: "Show related assets",
  showFullText: "full text",
  // sort: "sort",
  submit: "Submit",
  // unassign: "unassign",
  undoChanges: "Undo Changes",
  // unselect: "Unselect",
  uploadGridConnectionValues: "Upload List of Grid Connection Values",
  yes: "Yes",
};
