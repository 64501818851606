import { subscript } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  category: "Category/Class/Equipment",
  certificateCode: "ZEREZ ID",
  certificateIssueDate: "Certificate Issue Date",
  certificateNorm: "Norm",
  certificateNumber: "Certificate Number",
  certificateType: "Verification Document",
  certificateValidity: "Certificate Validity",
  certificateValidityStatus: "Validity Status",
  // certificationBody: "Certification Body",
  certifyingAuthority: "Certification Body",
  // class: "Category/Class/Equipment",
  documentType: "Verification Document",
  fastVoltageChangeTurnOffAtRatedPower: "Rapid voltage change: Disconnection at rated power ",
  fastVoltageChangeTurnOnAtRatedConditions:
    "Rapid voltage change: Connection at nominal conditions (of the primary energy carrier)",
  fastVoltageChangeTurnOnWithoutSpecifications:
    "Rapid voltage change: Connection without provisions (regarding the primary energy carrier)",
  fastVoltageChangeWorstCaseDuringGeneratorStageSwitching:
    "Rapid voltage change: Most adverse case when switching between generator levels",
  initialShortCircuitAlternatingCurrent: "Initial Short-Circuit Current",
  kpiTitle: "Total certificates",
  manufacturer: "Manufacturer",
  maxActivePower: "Maximum Active Power",
  maxApparentPower: "Maximum Apparent Power",
  maxActivePower09: `Maximum Active Power of the generation unit at 0.9 U${subscript("n")}`,
  // name: "Certificate",
  // netFeedIn: "Grid Connection",
  primaryEnergy: "Primary Energy",
  primaryEnergySource: "Primary Energy Source",
  ratedActivePower: "Rated Active Power",
  ratedApparentPower: "Rated Apparent Power",
  ratedCurrent: "Rated Current",
  ratedVoltage: "Rated Voltage",
  restrictions: "Restrictions",
  revision: "Revision",
  replacedByCertificate: "Replaced by Certificate",
  replacedCertificate: "Follows Certificate",
  shortCircuitPeakCurrent: "Short Whithstand Current",
  systemFlickerCoefficientCY30: `Flicker, system flicker coefficient c${subscript("Ψ")}, 30°`,
  systemFlickerCoefficientCY50: `Flicker, system flicker coefficient c${subscript("Ψ")}, 50°`,
  systemFlickerCoefficientCY70: `Flicker, system flicker coefficient c${subscript("Ψ")}, 70°`,
  systemFlickerCoefficientCY85: `Flicker, system flicker coefficient c${subscript("Ψ")}, 85°`,
  operation1FlickerStepFactor30: `Flicker step factor k${subscript("f(Ψ)")}, 30°`,
  operation1FlickerStepFactor50: `Flicker step factor k${subscript("f(Ψ)")}, 50°`,
  operation1FlickerStepFactor70: `Flicker step factor k${subscript("f(Ψ)")}, 70°`,
  operation1FlickerStepFactor85: `Flicker step factor k${subscript("f(Ψ)")}, 85°`,
  operation1MaxNumberN120: `Max number of switching operations N${subscript("120")}`,
  operation1MaxSwitchN10: `Max number of switching operations N${subscript("10")}`,
  operation1VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation1VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation1VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation1VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operation2FlickerStepFactor30: `Flicker step factor k${subscript("f(Ψ)")}, 30°`,
  operation2FlickerStepFactor50: `Flicker step factor k${subscript("f(Ψ)")}, 50°`,
  operation2FlickerStepFactor70: `Flicker step factor k${subscript("f(Ψ)")}, 70°`,
  operation2FlickerStepFactor85: `Flicker step factor k${subscript("f(Ψ)")}, 85°`,
  operation2MaxNumberN120: `Max number of switching operations N${subscript("120")}`,
  operation2MaxSwitchN10: `Max number of switching operations N${subscript("10")}`,
  operation2VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation2VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation2VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation2VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operation3FlickerStepFactor30: `Flickerformfaktork${subscript("f(Ψ)")}, 30°`,
  operation3FlickerStepFactor50: `Flicker step factor k${subscript("f(Ψ)")}, 50°`,
  operation3FlickerStepFactor70: `Flicker step factor k${subscript("f(Ψ)")}, 70°`,
  operation3FlickerStepFactor85: `Flicker step factor k${subscript("f(Ψ)")}, 85°`,
  operation3MaxNumberN120: `Max number of switching operations N${subscript("120")}`,
  operation3MaxSwitchN10: `Max number of switching operations N${subscript("10")}`,
  operation3VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation3VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation3VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation3VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operationAllWorstCase: `Worst case of all switching operation k${subscript("i max")}`,
  netFeedIn: "Grid Connection",
};
