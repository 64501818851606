export default {
  certificateUploadProcess: {
    createNew: {
      description:
        "Laden Sie ein neues Zertifikat hoch. Sie können dazu die Daten manuell eintragen oder eine Datei mit den Netzanschlusswerten hochladen.",
      title: "Neues Zertifikat",
    },
    replaceExisting: {
      description:
        "Ziehen Sie ein Zertifikat zurück und ersetzen Sie es durch ein neues Zertifikat. (Gilt nur für die Normen VDE-AR-N 4110, VDE-AR-N 4120 und VDE-AR-N 4130.)",
      title: "Vorhandenes Zertifikat ersetzen",
    },
    edit: {
      description: "Bearbeitrn Sie ein existierendes Zertifikat.",
      title: "Vorhandenes Zertifikat bearbeiten",
    },
    replaceCertificatePropmt: "Geben Sie die Zertifikatsnummer des Zertifikats ein, das Sie ersetzen möchten!",
  },
  noOptionsText: "Keine Zertifikate zum Ersetzen gefunden",
  addUnit: "Einheit hinzufügen",
  createUnit: "Einheit erstellen",
  linkexistingUnit: "Vorhandene Einheit verknüpfen",
  linkexistingUnitDescription: "Modell Namen oder Seriennummer eingeben",
  animationTitle: "...Daten werden geladen",
  category: "Kategory",
  certificationAuthority: "Zertifizierungsstelle",
  certificateIssueDate: "Ausgabedatum des Zertifikates",
  comment:
    "Bitte überprüfen Sie die gescannten Daten aus dem hochgeladenen Datenblatt und nehmen Sie gegebenenfalls Anpassungen an den Geräteparametern vor. Klicken Sie zur Bestätigung auf die Schaltfläche „Senden“.",
  dataSheet: "Data Sheet",
  discardChanges: "Änderungen verwerfen?",
  docs: "Hochgeladene Dokumente",
  errorMessages: {
    categoryInvalidForNorm: "Diese Kategorie ist für diese Norm nicht gültig!",
    categoryRequired: "Die Kategorie ist erforderlich!",
    someUnitsHaveErrors: "Einige Einheiten haben Fehler",
    certificateAlreadyExists: "Dieses Zertifikat existiert bereits",
    certificateCoverSheetRequired: "Das Deckblatt des Zertifikats ist erforderlich",
    perturbationsTestReportRequired: "Das Dokument Zertifikat/Prüfbericht NA-Schutz ist erforderlich",
    softwareVersionRequired: "Die Software-Version ist erforderlich",
    manufacturerUnitAlreadyExists: "Dieser Hersteller hat bereits eine Einheit mit diesem Namen",
    manufactucturerIdDifferentToUnitManufacturer:
      "Der Hersteller stimmt nicht mit dem Hersteller der verknüpfen Einheite(s) überein",
    modelAlreadyExists: "Dieses Modell existiert bereits",
    normRequired: "Die Norm ist erforderlich",
    requieredIfGreater10: "Dieses Feld ist erforderlich, wenn Norm größer oder gleich 10 ist",
    unitHasCountErrors: "Die Einheit {unitName} hat {errorCount} Fehler",
    duplicateUnitNames: "Ein Zertifikat kann nicht merhere Einheiten mit dem gleichen Namen haben",
    requieredIfCategory: "Dieses Feld ist bei der Kombination aus Norm & Kategorie erforderlich",
    requieredIfCategoryAndMaxRated:
      "Dieses Feld ist bei der Kombination aus Norm, Katefgorie & Maximaler Wirkleistung erforderlich",
  },
  field: {
    category: "Kategory",
    certificationAuthority: "Zertifizierungsstelle",
    certificateIssueDate: "Ausgabedatum des Zertifikates",
    manufacturer: "Hersteller",
    validityStatus: "Gültigkeitsstatus",
  },
  feedBack: {
    error: {
      title: "Fehler beim Erstellen des Zertifikats",
      body: "Es gab ein Problem beim Erstellen des Zertifikats. Bitte versuchen Sie es erneut.",
    },
    success: {
      title: "Zertifikat Erstellt",
      body: "Das Zertifikat und die zugehörigen Einheiten wurden erfolgreich hochgeladen.",
    },
    successReplace: {
      title: "Zertifikat ersetzt",
      body: "Das neue Zertifikat und die zugehörigen Einheiten wurden erfolgreich hochgeladen. Das bisherige Zertifikat wurde erfolgreich ersetzt.",
    },
    successEdit: {
      title: "Zertifikat geändert",
      body: "Das Zertifikat und die zugehörigen Einheiten wurden erfolgreich geändert.",
    },
  },
  info: "Zertifikatsinformationen",
  label: "Zertifikat hochladen",
  pdfCertificate: "PDF Certificate",
  renameFile: "Datei umbenennen",
  resetButtonLabel: "Änderungen zurücksetzen",
  title: "Einheiten & Parameter",
  unsavedChanges:
    "Sie haben nicht gespeicherte Änderungen vorgenommen. Wenn Sie den Upload-Vorgang verlassen, gehen alle Änderungen verloren. Möchten Sie den Vorgang verlassen und alle Änderungen verwerfen?",
  uploadFormDescription:
    "Sie können entweder eine Excel-Datei für die automatische Datenüberprüfung hochladen. Überprüfen Sie dann die Korrektheit der Daten und klicken Sie auf Senden. Alternativ können Sie die erforderlichen Daten manuell eingeben, überprüfen und das Formular senden.",
  uploadFormTitle: "Zertifikat hochladen",
  noUnitsFound: "Keine passenden Einheiten gefunden",
};
