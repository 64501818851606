export const BullitTitle = ({ title, nextParagraph = true }: { title: string; nextParagraph?: boolean }) =>
  nextParagraph ? `<br /><br /><b>${title}</b><br /><br />` : `<b>${title}</b>`;

export const nextParagraph = () => `<br /><br />`;

export const nextLine = () => `<br />`;

export const BullitList = ({ content }: { content: string[] }) =>
  `<ul>${content.map((item) => `<li>${item}</li>`).join("")}</ul>`;

export const LegalNoteLink = ({ url, label }: { url: string; label: string }) => `<a href=${url}>${label}</a>`;

export const MailTo = ({ mailTo, label }: { mailTo: string; label: string }) =>
  `<a href="mailto: ${mailTo}">${label}</a>`;

export const subscript = (value: string) => `<sub>${value}</sub>`;

export const superscript = (value: string) => `<sup>${value}</sup>`;
