import { BullitTitle, LegalNoteLink, MailTo, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  alternativeDisputeResolution: "Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:v",
  alternativeDisputeResolutionContent: `
  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die unter ${LegalNoteLink({
    url: "https://ec.europa.eu/consumers/odr",
    label: "https://ec.europa.eu/consumers/odr",
  })} zu finden ist.
    ${nextLine()}Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
  `,
  disputeResolution: "Online Streitbeilegung",
  generalInformation: `
    ${BullitTitle({ title: "Anschrift:", nextParagraph: false })}
    ${nextLine()}Oranienburger Str. 45
    ${nextLine()}10117 Berlin
    ${nextParagraph()}${BullitTitle({ title: "Telefon:", nextParagraph: false })}
    + 49 (0) 30/ 30 10 15 05 -0
    ${nextParagraph()}${BullitTitle({ title: "EMail:", nextParagraph: false })}
    ${MailTo({ mailTo: "info@wind-fgw.de", label: "info@wind-fgw.de" })}
    ${nextParagraph()}${BullitTitle({ title: "Internet:", nextParagraph: false })}
    ${LegalNoteLink({
      url: "https://www.wind-fgw.de",
      label: "www.wind-fgw.de",
    })}
    ${nextParagraph()}${BullitTitle({ title: "Geschäftsführer:", nextParagraph: false })}
    Dipl.-Ing. Jan Liersch
    ${nextParagraph()}${BullitTitle({ title: "Amtsgericht:", nextParagraph: false })}
    Berlin
    ${nextParagraph()}${BullitTitle({ title: "Vereinsregister", nextParagraph: false })}
    AG Charlottenburg, VR 29989 B
    ${nextParagraph()}${BullitTitle({ title: "USt-ID:", nextParagraph: false })}
    DE203774047
  `,
  header: "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien",
};
