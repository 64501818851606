import { AuthenticatedTemplate } from "@azure/msal-react";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Route, useRoutes } from "routes";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import ResponsiveNavbar from "./ResponsiveNavbar";
interface Props {}

export function getIsRouteSelected({ route, location }: { route: Route; location: string }): boolean {
  switch (location) {
    case "/":
      return route.label === translations.pages.home.label;
    case "/configurator":
      return route.label === translations.pages.configurator.label;
    default:
      return (
        (`/${location.split("/")[1]}` === route.path && route.label === translations.pages.home.label) ||
        location === route.path
      );
  }
}
export const MainNavBar: React.FC<Props> = () => {
  const location = useLocation().pathname;
  const routes = useRoutes();

  return (
    <AuthenticatedTemplate>
      <Box sx={{ display: "flex", minWidth: "20rem", "@media (max-width: 992px)": { display: "none" } }}>
        {routes.map((route) => {
          return route.children
            ?.filter((route) => route.menuItem)
            .map((childRoute) => {
              const isActive = getIsRouteSelected({ route: childRoute, location });
              return (
                <Tab
                  disableRipple
                  sx={{
                    color: isActive ? ordinaryColors.primaryBrand : ordinaryColors.functionalBlack,
                    textTransform: "none",
                    fontSize: "18px",
                    fontWeight: 400,
                    opacity: 1,
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontStyle: "normal",
                    lineHeight: "143% /* 25.74px */",
                    letterSpacing: "0.17px",
                  }}
                  key={childRoute.path} // Add unique key prop
                  label={childRoute?.label}
                  to={childRoute?.path}
                  component={Link}
                />
              );
            });
        })}
      </Box>
      <Box sx={{ display: "none", "@media (max-width: 992px)": { display: "flex" } }}>
        <ResponsiveNavbar routes={routes} location={location} />
      </Box>
    </AuthenticatedTemplate>
  );
};

export default MainNavBar;
