import { LegalNoteLink, nextLine, nextParagraph, superscript } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  databaseCardLabel: "Units & Certificates",
  databaseCardText: "Access the Public Register instantly to view publicly available unit certificates",
  days: "Days",
  descriptionPart1:
    "The task of the registry is to contribute to a simplification of the grid connection process in the electricity sector by providing unit and component certificates. The registry offers manufacturers, grid operators, certification bodies and planners a central location where they can publish and retrieve unit and component certificates quickly and securely. ",
  descriptionPart2: "The register is operated by FGW e.V.",
  descriptionPart3:
    "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien - is an association for the promotion of scientific purposes which, together with its members from the entire energy sector, has been involved in standardisation work, certification and networking of companies in the energy sector since its foundation in 1985. Test specifications and technical guidelines are developed in open committees. This supports the construction, expansion and smooth operation of renewable and decentralised energy systems. FGW e.V. also takes on fiduciary tasks and is involved in research projects.",
  faq: "FAQ",
  faqMessage: "Learn about topics that are likely to interest you.",
  firstPhaseMessage:
    "During the voluntary use phase, manufacturers already have the opportunity to publish their certificates in the register.",
  firstPhaseTitle: `
    Voluntary use phase
    ${nextLine()}(since April 8th, 2024)
  `,
  headline: "ZEREZ - Central registry for unit and component certificates",
  hours: "Hours",
  launchBody: ({ certificateCount }: { certificateCount: number | undefined }) => `
    Welcome to the central register for unit and component certificates (ZEREZ) by FGW e. V.
    ${nextParagraph()}The central register for unit and component certificates has been available for use since April 8, 2024.
    ${
      certificateCount &&
      `${nextParagraph()}There are currently ${certificateCount} certificates on file in the register.`
    }
    ${nextParagraph()}Together with the users, FGW e. V. would like to use the next few months to test and improve the functionality of the register. To this purpose, manufacturers and certification bodies initially have the non-binding option of publishing and maintaining their unit certificates in the register. Network operators will soon be able to process all available certificates via an adapted data interface.
  `,
  logInCardLabel: "Log In",
  logInCardText: "To access all features, simply log in with your username and password",
  minutes: "Minutes",
  otherDocuments: "Notes, templates and documents",
  otherDocumentsMessage: "Here you will find information, templates and documents for use with the register.",
  ourVision: "Our Vision",
  ourVisonMessage: "See who ZEREZ is primarily designed for and what the goals of this project are.",
  question: "What do you want to do?",
  registrationCardLabel: "Registration",
  registrationCardText: "Don`t have an account yet? Click here to create one.",
  seconds: "Seconds",
  secondOrdinanceMessage: `
    According to  
    ${LegalNoteLink({
      url: "https://www.bmwk.de/Redaktion/DE/Artikel/Service/Gesetzesvorhaben/referentenentwurf-zweite-verordnung-zur-aenderung-der-elektrotechnische-eigenschaften-nachweis-verordnung.html#:~:text=Durch%20die%20Zweite%20Verordnung%20zur,eines%20einzelnen%20Absatzes%20ge%C3%A4ndert%20werden.",
      label: "the Second Ordinance amending the Electrotechnical Properties Verification Ordinance",
    })} 
    of the Federal Ministry of Economics and Climate Protection (BMWK), the obligation of market participants to use the central register will take effect approximately eight months after the ordinance comes into force. FGW e. V. is preparing to fulfil this usage obligation with ZEREZ.
  `,
  secondOrdinanceTitle: `
  Mandatory use Phase
    ${nextLine()}(As of February 1${superscript("st")}, 2025)
  `,
  titleBody: "Welcome to the website of the Central Registry for Unit and Component Certificates (ZEREZ)!",
};
