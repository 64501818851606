export default {
  add: "Hinzufügen",
  addNorm: "+Norm hinzufügen",
  addSimulationSoftware: "+Simulationssoftware hinzufügen",
  apply: "Anwenden",
  approve: "Genehmigen",
  // assign: "Zuordnen",
  // attributeSets: "Attribut Sets",
  back: "Zurück",
  cancel: "Abbrechen",
  // change: "Ändern",
  collapse: "Alle einklappen",
  confirm: "Bestätigen",
  // continue: "Fortsetzen",F
  // create: "Erstellen",
  // createdBy: "created by",
  delete: "Löschen",
  deny: "Ablehnen",
  // done: "Fertig",
  // download: "Download",
  // edit: "Berbeiten",
  errorReportButtonLabel: "Fehler melden",
  expand: "Alle ausklappen",
  // filters: "Filter",
  // hide: "Zugehörige Assets ausblenden",
  homePage: "Startseite",
  logIn: "Anmelden",
  logOut: "Abmelden",
  new: "Neu",
  // newLog: " Neuer Log",
  // next: "Weiter",
  no: "Nein",
  continue: "Weiter",
  // OK: "OK",
  refreshTable: "Tabelle aktualisieren",
  reload: "Neu laden",
  // replace: "Ersetzen",
  // reserve: "Reservieren",
  reset: "Zurücksetzen",
  resetAllFilters: "Alle Filter zurücksetzen",
  // run: "Ausführen",
  save: "Speichern",
  saveChanges: "Änderungen speichern",
  // send: "Senden",
  sendInvite: "Einladung senden",
  // settings: "Einstellungen",
  // show: "Zugehörige Assets anzeigen",
  showFullText: "Vollständigen Text anzeigen",
  // sort: "sort",
  submit: "Absenden",
  // unassign: "Abwählen",
  undoChanges: "Änderungen rückgängig machen",
  // unselect: "Abwählen",
  uploadGridConnectionValues: "Liste der Netzanschlusswerte hochladen",
  yes: "Ja",
};
