import { LegalNoteLink, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  databaseCardLabel: "Einheiten und Zertifikate",
  databaseCardText:
    "Greifen Sie sofort auf das öffentliche Register zu, um öffentlich verfügbare Einheitenzertifikate anzuzeigen",
  days: "Tage",
  descriptionPart1:
    "Aufgabe des Registers ist es, durch die Bereitstellung von Einheiten- und Komponentenzertifikaten zu einer Vereinfachung des Netzanschlussprozesses dezentraler Energieerzeugungsanlagen beizutragen. Dazu bietet das Register Herstellern, Netzbetreibern, Zertifizierungsstellen und Planern einen zentralen Ort, an dem sie schnell und sicher Einheiten- und Komponentenzertifikate veröffentlichen und abrufen können.",
  descriptionPart2: "Das Register wird von FGW e.V. betrieben.",
  descriptionPart3:
    "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien - ist ein Verein zur Förderung wissenschaftlicher Zwecke, der sich seit seiner Gründung im Jahre 1985 gemeinsam mit seinen Mitgliedern aus der gesamten Energiebranche mit Normungsarbeit, Zertifizierung und Vernetzung von Unternehmen im Energiesektor beschäftigt. Es werden Prüfvorschriften und technische Richtlinien in offenen Gremien erarbeitet. Dadurch können Aufbau, Ausbau und der reibungslose Betrieb von Anlagen der erneuerbaren und dezentralen Energien unterstützt werden. Weiterhin übernimmt FGW e.V. treuhänderische Aufgaben und wirkt bei Forschungsprojekte mit. ",
  faq: "FAQ",
  faqMessage: "Erfahren Sie mehr über Themen, die Sie wahrscheinlich interessieren.",
  firstPhaseMessage:
    "Während der freiwilligen Nutzungsphase haben Hersteller bereits die Möglichkeit, ihre Zertifikate im Register zu veröffentlichen.",
  firstPhaseTitle: `
    Freiwillige Nutzungsphase
    ${nextLine()}(seit 8. April 2024)
  `,
  headline: "ZEREZ - Zentrales Register für Einheiten- und Komponentenzertifikate",
  hours: "Stunden",
  launchBody: ({ certificateCount }: { certificateCount: number | undefined }) => `
    Willkommen zum zentralen Register für Einheiten- und Komponentenzertifikate (ZEREZ) der FGW e. V.
    ${nextParagraph()}Seit dem 8. April 2024 steht das zentrale Register für Einheiten- und Komponentenzertifikate zur Nutzung zur Verfügung.
    ${certificateCount && `${nextParagraph()}Im Register sind z.Z. ${certificateCount} Zertifikate hinterlegt.`}
    ${nextParagraph()}Die FGW e. V. möchte gemeinsam mit den Nutzern die nächsten Monate nutzen, um die Funktionsfähigkeit des Registers zu erproben und zu verbessern. Dazu haben zunächst Hersteller und Zertifizierungsstellen die unverbindliche Möglichkeit, ihre Einheitenzertifikate im Register zu veröffentlichen und zu pflegen. Netzbetreiber werden zeitnah die Möglichkeit haben, alle verfügbaren Zertifikate über eine angepasste Datenschnittstelle zu verarbeiten.
  `,
  logInCardLabel: "Anmelden",
  logInCardText: "Melden Sie sich einfach mit Ihrem Benutzernamen und Passwort an, um auf alle Funktionen zuzugreifen",
  minutes: "Minuten",
  otherDocuments: "Hinweise, Vorlagen und Dokumente",
  otherDocumentsMessage: "Hier finden Sie Hinweise, Vorlagen und Dokumente zur Nutzung des Registers.",
  ourVision: "Our Vision",
  ourVisonMessage: "Sehen Sie, für wen ZEREZ hauptsächlich konzipiert ist und welche Ziele dieses Projekt verfolgt.",
  question: "Was möchten Sie tun?",
  registrationCardLabel: "Registrierung",
  registrationCardText: "Sie haben noch kein Konto? Klicken Sie hier, um eines zu erstellen.",
  seconds: "Sekunden",
  secondOrdinanceMessage: `
    Laut der 
    ${LegalNoteLink({
      url: "https://www.bmwk.de/Redaktion/DE/Artikel/Service/Gesetzesvorhaben/referentenentwurf-zweite-verordnung-zur-aenderung-der-elektrotechnische-eigenschaften-nachweis-verordnung.html#:~:text=Durch%20die%20Zweite%20Verordnung%20zur,eines%20einzelnen%20Absatzes%20ge%C3%A4ndert%20werden.",
      label: "Zweiten Verordnung zur Änderung der Elektrotechnische-Eigenschaften-Nachweis-Verordnung",
    })} 
    des Bundesministeriums für Wirtschaft und Klimaschutz (BMWK) wird die Pflicht der Marktteilnehmer zur Nutzung des zentralen Registers acht Monate nach Inkrafttreten der Verordnung greifen. FGW e. V. bereitet sich darauf vor, diese Nutzungspflicht mit ZEREZ auszuüben.
  `,
  secondOrdinanceTitle: `
  Verpflichtende Nutzungsphase
    ${nextLine()}(Beginn: 1. Februar 2025)
  `,
  titleBody:
    "Herzlich willkommen auf der Webseite des Zentralen Registers für Einheiten- und Komponentenzertifikate (ZEREZ)!",
};
